<template>
    <section>
        <div class="panel has-text-weight-semibold">
        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
            <div class="columns is-mobile">
                <div class="column is-size-6 has-text-centered">MY STORES</div>
            </div>
        </div>

        <div class="columns">
            <div class="column is-4 has-text-left">
                <date-range-selector
                    :dateRange="salesDateRange"
                    @set-date-range="setDateRange"
                    >
                </date-range-selector>
            </div>
        </div> 
        <hr>

        <div class="panel-block">
            <b-table class="control" 
                :data='storesWithData'
                :paginated="isPaginated"
                :per-page="perPage"
                paginationPosition="both"
                sortIcon="arrow-up"
                detailed
                striped
                detail-key="id"
                sortIconSize="is-small"
                default-sort=storeName
                defaultSortDirection='asc'
                >

            <template>
                <b-table-column class="is-2 has-text-right" field="StoreId" label="Set Store" sortable sorticon v-slot="props">
                    <b-tooltip position="is-left" label="Set Active Store" dashed >
                        <b-button class="is-primary is-small"  v-on:click="setActiveStore(props.row)" outlined rounded>⛽</b-button>
                    </b-tooltip> 
                </b-table-column>                
                <b-table-column field="storeName" label="Store Name" sortable sorticon searchable  v-slot="props">
                    <b-tooltip :label="storeAddress(props.row)">
                        {{ toTitleCase(props.row.storeName) }}
                    </b-tooltip>                    
                </b-table-column>
                <b-table-column numeric field="merchSales" label="Merch" v-slot="props">
                    {{ totalAmount(props.row.summary, "merchSales") | formatCurrency }}
                </b-table-column>
                <!-- <b-table-column numeric field="fuelSales" label="Fuel" v-slot="props">
                    {{ totalAmount(props.row.summary, "fuelSales") | formatCurrency }}
                </b-table-column> -->
                <b-table-column numeric field="fuelVol" label="Fuel Gal." v-slot="props">
                     {{ totalFuelVolume(props.row.summary) | formatNumber }}
                </b-table-column>
                <b-table-column numeric field="cashAmount" label="Cash Sales" v-slot="props">
                    {{ totalAmount(props.row.summary, "cashAmount") | formatCurrency }}
                </b-table-column>
                <b-table-column numeric field="netLottery" label="Net Lottery" v-slot="props">
                    {{ netLottery(props.row.summary) | formatCurrency }} 
                </b-table-column>
                <b-table-column numeric field="cashIncomeExpe" label="Cash Inc/Exp" v-slot="props">
                    {{ totalAmount(props.row.summary, "totalCashIncome") - totalAmount(props.row.summary, "totalCashExpense") | formatCurrency }} 
                </b-table-column>
                <b-table-column numeric field="totalSafeDrop" label="Safe Drop" v-slot="props">
                    {{ totalAmount(props.row.summary, "totalSafeDrop") | formatCurrency }}
                </b-table-column>
                <b-table-column numeric field="cashActualOnhand" label="Actual Cash" v-slot="props">
                    {{ totalAmount(props.row.summary, "cashActualOnhand") | formatCurrency }}
                </b-table-column>
                <b-table-column numeric field="cashOverShort" label="Over/Short" v-slot="props">
                    {{ totalAmount(props.row.summary, "cashOverShort") | formatCurrency }}
                </b-table-column>
                
                <b-table-column field="status" label="Status" v-slot="props">
                  <b-tooltip :label="diffToDateInHuman(props.row.lastHeartBeat)">
                    <span v-if="diffToDateInMinutes(props.row.lastHeartBeat) < 15">
                    🟢
                  </span>
                  <span v-else>
                    🔴
                  </span>
                </b-tooltip>
                  
                </b-table-column>                                          
            </template>

            <template slot="detail" slot-scope="props">
                <b-table :data="props.row.summaryByDate">
                    <b-table-column field="date" label="Date" v-slot="props">
                        {{ (props.row.length > 0 ? props.row[0].date : "") | formatDate }}
                    </b-table-column>
                    <b-table-column numeric field="merchSales" label="Merch Sales" v-slot="props">
                        {{ totalAmount(props.row, "merchSales") | formatCurrency }}
                    </b-table-column>
                    <!-- <b-table-column numeric field="fuelSales" label="Fuel Sales" v-slot="props">
                        {{ totalAmount(props.row, "fuelSales") | formatCurrency }}
                    </b-table-column> -->
                    <b-table-column numeric field="fuelVol" label="Fuel Gal." v-slot="props">
                        {{ totalFuelVolume(props.row, "fuelVol") | formatNumber }}
                    </b-table-column>
                    <b-table-column numeric field="cashAmount" label="Cash Sales" v-slot="props">
                        {{ totalAmount(props.row, "cashAmount") | formatCurrency }}
                    </b-table-column>
                    <b-table-column numeric field="netLottery" label="Net Lottery" v-slot="props">
                        {{ netLottery(props.row) | formatCurrency }} 
                    </b-table-column>
                    <b-table-column numeric field="cashIncomeExpe" label="Cash Inc/Exp" v-slot="props">
                        {{ totalAmount(props.row, "totalCashIncome") - totalAmount(props.row, "totalCashExpense") | formatCurrency }} 
                    </b-table-column>
                    <b-table-column numeric field="totalSafeDrop" label="Safe Drop" v-slot="props">
                        {{ totalAmount(props.row, "totalSafeDrop") | formatCurrency }}
                    </b-table-column>
                    <b-table-column numeric field="cashActualOnhand" label="Actual Cash" v-slot="props">
                        {{ totalAmount(props.row, "cashActualOnhand") | formatCurrency }}
                    </b-table-column>
                    <b-table-column numeric field="cashOverShort" label="Over/Short" v-slot="props">
                        {{ totalAmount(props.row, "cashOverShort") | formatCurrency }}
                    </b-table-column>
                </b-table>
            </template>

            <template slot="empty">
                <data-loading 
                    :isLoading="isLoading" 
                    :isFullPage="isFullPage"> 
                </data-loading>
            </template>
            <template slot="top-left">
                <router-link to="/user/storesmenu">
                    <b-button class="is-primary">Go to simple stores list!</b-button>
                </router-link>
            </template>
        </b-table>

        </div>
        </div>
    </section>
</template>

<script>

import UserStore from '../../../../store/UserStore'
import DataLoading from '../../../../components/app/DataLoading'
import DateRangeSelector from '../../../../components/app/DateRangeSelector'

import axios from 'axios'
import moment from 'moment'

export default {

    components: {
        DataLoading,
        DateRangeSelector
    },

    data() {
        return {
            stores: [],
            storesWithData: [],
            salesDateRange: [],
            startDate: new Date(),
            endDate: new Date(),
            isPaginated: true,
            perPage: 20,
            isLoading: true,
            isFullPage: true,
            apiHeader: {
                    headers: {
                        'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                        'Refresh-Token': UserStore.getters.getRefreshToken,
                        'correlation-id': this.$uuid.v1()
                    },                   
                }
        }
    },

    methods: {
        async fetchStores() {
            
            if (UserStore.getters.getUser) {
                this.isLoading = true
                const url = process.env.VUE_APP_API_ROOT_URL + "users/" +
                            UserStore.getters.getUser.id +
                            "/stores" 

                await axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.stores = response.data.data
 
                        this.fetchMyStoresSummary()
                    })
                    .catch( (err) => {
                        if (err.response) {

                            if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status)
                                this.isLoading = false
                                if (err.response.status === 403) {
                                    alert('Session Expired. Please log in again!')
                                    UserStore.commit('userLogout')
                                }                        
                            }
                    })
            }

        },

        async fetchMyStoresSummary() {
            // var today = new Date()

            // if (today.getDate() == 1) {
            //     today.setMonth(today.getMonth() - 1)
            // }
            // var firstDay=new Date(today.getFullYear(), today.getMonth(), 1).toISOString().split('T')[0]
            // var lastDay = new Date(today.getFullYear(), today.getMonth()+1, 0).toISOString().split('T')[0]


            var urlArray = []
            this.storesWithData = []

            this.stores.forEach(store => {
                var url = process.env.VUE_APP_API_ROOT_URL + 'stores/' + store.id +
                     "/sales/shifts/net?size=50"  + "&fromDate="+this.startDate+"&toDate="+this.endDate
                urlArray.push(axios.get(url,this.apiHeader))

                Promise.all(urlArray)
                    .then(res => {
                        store.summary = res[res.length - 1].data.data
                        store.summaryByDate = this.groupArrayByProp(store.summary,"date")
                        this.storesWithData.push(store)
                    })
                    .then(() => {
                        this.isLoading = false
                    })
                    .catch(() => {
                        this.$buefy.dialog.alert({
                            title: "Error",
                            message: "Failed to collect data for store: <B>" + store.storeName + "</B><br>Click on Link on top to use simple store list.",
                            type: "is-danger"
                        })
                        // To avoid hangup on error
                        this.isLoading = false
                        Promise.resolve({ data: "success" })
                    })
            })

        },

        async fetchFromCloud(url) {
            axios.get(url,this.apiHeader)
                .then(response => {
                    return response.data.data
                })
                .catch(err => {
                    console.log("Error: Failed to fetch summary", err)
                    return []
                })
        },

        setActiveStore(row) {
            UserStore.dispatch('setActiveStoreDetails', row.id)
        },

        diffToDateInMinutes(unixTimestamp) {
            var today = moment(new Date())
            var otherDate = moment.unix(unixTimestamp)
            var duration = moment.duration(today.diff(otherDate));
            var diffMins = duration.asMinutes()
            return Math.floor(diffMins)
        },

        diffToDateInHuman(unixTimestamp) {
            var diffMins = this.diffToDateInMinutes(unixTimestamp)
            return "Last update "+moment.duration(diffMins, "minutes").humanize() + " ago"

        },

        totalAmount(row, field) {
            var total = row.reduce((accum,item) => accum + item[field], 0)
            return total
        },

        totalFuelVolume(row) {
            var total = row.reduce((accum,item) => accum + item.storeFuelSales.reduce((tot,fuel) => tot + fuel.volume, 0), 0)
            return total
        },
        
        storeAddress(row) {
            return this.toTitleCase(row.addressLine1) + ", " +
                this.toTitleCase(row.city) + ", " +
                row.state + " " + row.zipCode
        },

        netLottery(row) {
            var onlineLottoSales = this.totalAmount(row, "onlineLottoSales")
            if (!onlineLottoSales) onlineLottoSales = 0

            var onlineLottoPayout = this.totalAmount(row, "onlineLottoPayout")
            if (!onlineLottoPayout) onlineLottoPayout = 0

            var instantLottoSales = this.totalAmount(row, "instantLottoSales")
            if (!instantLottoSales) instantLottoSales = 0

            var instantLottoPayout = this.totalAmount(row, "instantLottoPayout")
            if (!instantLottoPayout) instantLottoPayout = 0

            var machineLottoSales = this.totalAmount(row, "machineLottoSales")
            if (!machineLottoSales) machineLottoSales = 0

            var onlineLotteryCanceled = this.totalAmount(row, "onlineLotteryCanceled")
            if (!onlineLotteryCanceled) onlineLotteryCanceled = 0

            return onlineLottoSales - onlineLottoPayout +
                instantLottoSales - instantLottoPayout +
                machineLottoSales - onlineLotteryCanceled
        },

        groupDate(summary) {
            var groupedArray = this.groupArrayByProp(summary, 'date')
            return groupedArray
            // return this.groupArrayByProp(row.summary, 'date')
            // return row.summary
        },

        setDateRange(dateRange) {
            this.salesDateRange = [dateRange[0].toISOString().split('T')[0], dateRange[1].toISOString().split('T')[0]]
        },
        
    },

    watch: {
        salesDateRange() {
            this.startDate = this.salesDateRange[0]
            this.endDate = this.salesDateRange[1]
            this.stores = []
            this.fetchStores()
        }
        
    },

    mounted() {
        var today = new Date()

        if (today.getDate() == 1) {
            today.setMonth(today.getMonth() - 1)
        }
        this.startDate = new Date(today.getFullYear(), today.getMonth(), 1).toISOString().split('T')[0]
        this.endDate = new Date(today.getFullYear(), today.getMonth()+1, 0).toISOString().split('T')[0]

        this.salesDateRange=[this.startDate, this.endDate]
        // this.fetchStores()
    }

}
</script>